import React from "react";


const Video = () => {
    return (
        <h1>Video</h1>        
    );

}


export default Video;