import React from 'react';
import {
    Carousel
} from 'react-bootstrap';

const carousel = (props) => {
    let wrapper = React.createRef();
    return  <div ref={wrapper}>
            <Carousel>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="/images/python-curse.svg"
                alt="First slide"
                />
                <Carousel.Caption>
                <h3>Curso Básico de Python</h3>
                <p>Aprende las bases de Python.</p>
                </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://via.placeholder.com/550x200.png?text=First+Image"
                alt="First slide"
                />
                <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://via.placeholder.com/550x200.png?text=Second+Image"
                alt="Third slide"
                />

                <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src="https://via.placeholder.com/550x200.png?text=Third+Image"
                alt="Third slide"
                />

                <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                </Carousel.Caption>
            </Carousel.Item> */}
            </Carousel>
            </div>
};


export default carousel;