import React from "react";
import { useEffec,useState } from "react";
import Article from "../article/Article";
import Course from "../course/Course";
import Path from "../path/Path";
import Quizz from "../quizz/Quizz";
import Video from "../video/Video";


const Creator = () => {
    const [components,setComponents] = useState([])
    const saveSite = () => {
        
    }
    return (
        <>
        <h1>Site</h1>        
        <button onClick={()=>setComponents([...components, <Article/>])}>Article</button>
        <button onClick={()=>setComponents([...components, <Course/>])}>Course</button>
        <button onClick={()=>setComponents([...components, <Path/>])}>Path</button>
        <button onClick={()=>setComponents([...components, <Quizz/>])}>Quizz</button>
        <button onClick={()=>setComponents([...components, <Video/>])}>Video</button>

        {components}    
        <Article /> 
        </>
    );

}


export default Creator;