// Multiple choice question component

import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from 'formik';


const MulitpleChoice = (props) => {
  const [selectedChoice, setSelectedChoice] = useState(null);
  const getChoices = (question) => {
    const choices = question.choices.map((choice, index) => {
      return (
        <>
          <Form.Check
            type="radio"
            label={choice}
            name="formHorizontalRadios"
            id="formHorizontalRadios1"
          />
          <br />
        </>
      );
    });
    return choices;
  };

  const handleAnswer = () => {
    console.log("handleAnswer");
  };

  return (
    <>
      {/* {props.question.text}
            <p>Elige la respuesta correcta:</p>
            <form action=""></form>
            {getChoices(props.question)}
            
            <button onClick={handleAnswer}>Anterior</button>
            <button onClick={handleAnswer}>Siguiente</button> */}
      <div>
        <h1>Sign Up</h1>
        {/* <Formik
          initialValues={{
            picked: "",
          }}
          onSubmit={async (values) => {
            await new Promise((r) => setTimeout(r, 500));
            alert(JSON.stringify(values, null, 2));
          }}
        >
          {({ values }) => (
            <Form> */}
              <div id="my-radio-group">Picked</div>
              <div role="group" aria-labelledby="my-radio-group">
                <label>
                  <Field type="radio" name="picked" value="One" />
                  One
                </label>
                <label>
                  <Field type="radio" name="picked" value="Two" />
                  Two
                </label>
                {/* <div>Picked: {values.picked}</div> */}
              </div>

              {/* <button type="submit">Submit</button> */}
            {/* </Form>
          )}
        </Formik> */}
      </div>
    </>
  );
};

export default MulitpleChoice;
