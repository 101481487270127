import React from "react";
import { useState } from "react";
import { Formik, Field } from "formik";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import Article from "../article/Article";
// import Course from "../course/Course";
import Path from "../path/Path";
import Quizz from "../quizz/Quizz";
import Video from "../video/Video";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  //   border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  padding: "20px",
  width: "550px",
  minHeight: "100px",
};
const CreateElementModal = (props) => {
  const [openCreateElement, setOpenCreateElement] = useState(props.open);
  return (
    <>
      <Modal
        open={openCreateElement}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button onClick={() => setOpenCreateElement(false)}>X</button>
          {() => {
            switch (props.element) {
              case "article":
                return <Article />;
                break;
              case "course":
                return <Course />;
                break;
              case "path":
                return <Path />;
                break;
              case "quizz":
                return <Quizz />;
                break;
              case "video":
                return <Video />;
                break;
              default:
                break;
            }
          }}
          <button onClick={() => setOpenCreateElement(false)}>Cancel</button>
        </Box>
      </Modal>
    </>
  );
};

const SelectElementModal = (props) => {
  const [openSelectElement, setOpenSelectElement] = useState(props.open);
  const [selectedElement, setSelectedElement] = useState("article");
  const [openCreateElement, setOpenCreateElement] = useState(false);
  const showCreateElementModal = () => {
    if(openCreateElement) {

    return <CreateElementModal
      open={openCreateElement}
        element={selectedElement}
    />;
    }
    return null;
  }
  return (
    <>
      <Modal
        open={openSelectElement}
        // open={true}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <>
          <h2>Select element to be created.</h2>
          <button onClick={() => setOpenSelectElement(false)}>X</button>
          <br />
          <Formik>
            <>
              <label htmlFor="element">Element:</label>
              <Field
                onChange={(e) => setSelectedElement(e.target.value)}
                as="select"
                name="element"
              >
                <option value="activity">Activity</option>
                <option value="article">Article</option>
                <option value="quizz">Quizz</option>
                <option value="video">Video</option>
              </Field>
          <br />
              <button onClick={()=>setOpenCreateElement(true)}>Create</button>
              {/* <createElementModal /> */}
            </>
          </Formik>
          <button onClick={() => setOpenSelectElement(false)}>Cancel</button>
          <br />
            {showCreateElementModal()}
          </>
        </Box>
      </Modal>
    </>
  );
};

const Course = () => {
  const [openSelectElement, setOpenSelectElement] = useState(false);
  const [openCreateElement, setOpenCreateElement] = useState(false);
  const showSelectElementModal = () => {
    if (openSelectElement) {
      return <SelectElementModal open={openSelectElement} />;
    }
    return
  };

  return (
    <>
      <h1>Course</h1>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              name="title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
            />
            {errors.title && touched.title && errors.title}
            <button>Add element</button>
            <br />
            <button onClick={() => setOpenSelectElement(true)}>
              Create element
            </button>
            <br />
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
        {showSelectElementModal()}
    </>
  );
};

export default Course;
