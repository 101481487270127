
import React, {useEffect, useState} from 'react';
import './Header.css';
import UserPool from "../../authentication/UserPool";
import {useSelector} from "react-redux";


import { Button, Navbar, Nav, NavDropdown, Form, 
   FormControl } from 'react-bootstrap';
import { Link } from "react-router-dom";
// import { config } from 'dotenv';
import { user } from '../../authentication/authSlice';


const Header = ({}) => {
  const currentUser = useSelector(user);
  const authLinks = currentUser ? <> <Nav.Link as={Link} to="/logout">Logout</Nav.Link> {currentUser}</> :
    <>
      <Nav.Link as={Link} to="/login">Login</Nav.Link>
      <Nav.Link as={Link} to="/signup">Signup</Nav.Link>
    </>

  return <>
  <Navbar bg="light" expand="lg" fixed="top">
  <Navbar.Brand href="#home">AprendeloAhora</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link as={Link} to="/">Inicio</Nav.Link>
      {/* <Nav.Link as={  Link} to="/sde">Programacion</Nav.Link> */}
      <NavDropdown title="Pogramación" id="basic-nav-dropdown">
        <NavDropdown.Item href="/course" >Curso Python</NavDropdown.Item>
        <NavDropdown.Item href="/course" disabled>Curso Git</NavDropdown.Item>
        <NavDropdown.Item href="/course" disabled>Curso Django</NavDropdown.Item>
        <NavDropdown.Item href="/course"disabled>Curso AWS</NavDropdown.Item>
        {/* <NavDropdown.Item href="/course"disabled>Estructuras de datos y algoritmos</NavDropdown.Item> */}

      </NavDropdown>
      {/* <Nav.Link as={Link} to="/about">Nosotros</Nav.Link> */}
      {/* <NavDropdown title="Populares" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1" >Curso Python</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2" disabled>Curso Django</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3" disabled>Curso React</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown> */}
    </Nav>
    <Form inline>
      {/* <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-primary">Search</Button> */}
      {authLinks}
    </Form>
    
    
  </Navbar.Collapse>
</Navbar>
</>;
};

export default Header;