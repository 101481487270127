
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {CognitoUserAttribute, AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import {useNavigate} from 'react-router-dom';
import { updateConfigAuth } from './authSlice';

import UserPool from "./UserPool";


const Login = () => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const navigate = useNavigate();


    const handleSubmit = (e) => {
        e.preventDefault();
        var authenticationData = {
            Username: username ,
            Password: password
        };
        var userData = {
            Username: username,
            Pool: UserPool
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function(result) {
                // console.log('Access Token:' + JSON.stringify(jwt_decode(result.getAccessToken().getJwtToken())));
                // console.log('Id Token + ' + JSON.stringify(jwt_decode(result.getIdToken().getJwtToken())));
                // console.log('Refresh Token + ' + JSON.stringify(result.getRefreshToken().getToken()));

                console.log('Access Token:' + result.getAccessToken().getJwtToken());
                console.log('Id Token + ' + result.getIdToken().getJwtToken());
                console.log('Refresh Token + ' + result.getRefreshToken().getToken());
                console.log('HEre');
                const username = UserPool.getCurrentUser().username;
                dispatch(updateConfigAuth({currentUser: username, 
                    isAuthenticated: username?true:false,
                    jwtToken: result.getAccessToken().getJwtToken(),
                    jwtIdToken: result.getIdToken().getJwtToken()
                }));
                navigate('/')
            },
            onFailure: function(err) {
                console.error(err);
            },
            newPasswordRequired: (data)=>{
                console.log("New password required", data);
            }
        });
    }
    return (
        <>
            <h1>Login</h1>
            <form onSubmit={handleSubmit} >
                <label htmlFor="username">Username:</label>
                <input type="text" name="username" id="" onChange={(e)=>setUsername(e.target.value)}/>
                <label htmlFor="password">Password:</label>
                <input type="password" name="password"  id="" onChange={(e)=>setPassword(e.target.value)}/>
                <button type="submit">Send</button>
            </form>
        </>
    )
}


export default Login;