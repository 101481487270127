import React, { forwardRef } from "react";

import { Container } from "react-bootstrap";

import ReactPlayer from "react-player";
import Quiz from "../quiz/Quiz";

const MainContent = forwardRef((props, ref) => {
    return <> 
     {/* <Container fluid> */}
      {/* <h2>{props.currentCourse.topics[TOPIC_ID].title} | {props.finished === false ? "On progress" : "finished"}</h2> */}
      <h2 style={{color:'white'}}>{props.currentCourse.topics[props.topicId].title}</h2>
      {props.embeddedVideos}
      <br />
      {props.pickedUpVideo.type == "video" ? (
        <div className="player-wrapper">
          <ReactPlayer
            ref={ref}
            url={props.pickedUpVideo.url.replace("watch?v=", "embed/").split("&")[0]}
            // playing
            controls
            className="react-player"
            width="100%"
            height="100%"
            pip={true}
            stopOnUnmount={true}
            // muted
          />
        </div>
      ) : (
        <div className="quiz-wrapper">
          <Quiz />
        </div>
      )}
    {/* </Container>; */}
    </>
  });


export default MainContent;