import React, {useEffect} from 'react';
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {updateConfigAuth} from "./authSlice";

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        // var userData = {
        //     Username: 'JuanPerez',
        //     Pool: UserPool
        // };
        // let authenticationDetails = new AuthenticationDetails(authenticationData);
        // let cognitoUser = new CognitoUser(userData);
        // cognitoUser.signOut();
        const currentUser = UserPool.getCurrentUser();
        if(currentUser != null) {
            currentUser.signOut((data)=>{
                console.log(data);
                dispatch(updateConfigAuth({currentUser:null}))
                navigate("/")
            }
            );
        } else {
            dispatch(updateConfigAuth({currentUser:null}))
            navigate("/")
        }

    }, [])
    return <>
        <h1>Logout</h1>
    </>
};

export default Logout;