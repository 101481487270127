import React from "react";


const Quizz = () => {
    return (
        <h1>Quizz</h1>        
    );

}


export default Quizz;