// Multiple choice question component

import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";

const TrueFalse = (props) => {
  const getChoices = (question) => {
    const choices = props.question.choices.map((choice, index) => {
      return (
        <>
          <label>
            <Field type="checkbox" name="checked" value={choice} />
            {choice}
          </label>
        </>
      );
    });
    return (
      <>
        <div id="checkbox-group">Checked</div>
        <div role="group" aria-labelledby="checkbox-group">
          {choices}
        </div>

        {/* <button type="submit">Submit</button> */}
      </>
    );
  };

  return (
    <>
      {props.question.text}
      {getChoices(props.question)}
    </>
  );
};

export default TrueFalse;
