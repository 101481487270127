import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    jwtToken: null,
    currentUser: null,
    jwtIdToken: null,
  },
  reducers: {
    updateConfigAuth: (state, action) => {
      state = {...state, ...action.payload};
      return state
    },
    // updateJWTToken: (state, action) => {
    //   state.jwtToken = action.payload;
    // },
  },
})

// Action creators are generated for each case reducer function

export const authConfig = state => state.authConfig
export const user = state => state.authConfig.currentUser
export const jwtToken = state => state.authConfig.jwtToken
export const isAuthenticated = state => state.authConfig.isAuthenticated
export const jwtIdToken = state => state.authConfig.jwtIdToken

export const { updateConfigAuth } = authSlice.actions
export default authSlice.reducer