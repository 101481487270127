import React from "react";
import { useState, useEffect, useRef } from "react";

import "./course.css";
import SideMenu from "../sidemenu/SideMenu";
import MainContent from "./MainContent";
import CustomStepper from "./CustomStepper";
import { setTopicId, setTopicStepStatus, setCurrentStepId, fetchCourse, } from "./courseSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  courseTopicId,
  currentCourseId,
  courseObj,
  courseTopicStepStatus,
  courseCurrentTopicStepId,
} from "./courseSlice";
import { DURATION_TO_COMPLETE_VIDEO, defaultStateValues } from "./const";
import { courses as CoursesDef } from "./CoursesList";
import {user, jwtIdToken, isAuthenticated} from "../../authentication/authSlice";

const defaultState = { 
      courseId: defaultStateValues.courseId,
      topicId: defaultStateValues.topicId,
      currentStepId: defaultStateValues.currentStepId,
      videoPosition: defaultStateValues.videoPosition,
      isStepFinished: defaultStateValues.isFinished,
};

const getRemoteState = (courseId) => {
  return CoursesDef[courseId];
};

const getInitialState = (courseId) => {
  return (
    isAuthenticated ? getRemoteState(courseId) || defaultState : defaultState
  );
};

const Course = (props) => {
  const topicId = useSelector(courseTopicId);
  const course = useSelector(courseObj);
  const isFinished = useSelector(courseTopicStepStatus);
  const stepId = useSelector(courseCurrentTopicStepId) || 0;
  const courseId = useSelector(currentCourseId);
  const idToken = useSelector(jwtIdToken);
  const currentState = useState(getInitialState(courseId));

  const dispatch = useDispatch();
  const playerRef = useRef(null);

  const [courseRemoteState, setCourseRemoteState] = useState(
    CoursesDef[courseId]
  );
  const [pickedUpVideo, setPickedUpVideo] = useState(() => {
    return course.topics[topicId].steps[stepId];
  });

  let videoNumberHandler = (e, index) => {
    e.preventDefault();
    dispatch(setCurrentStepId(index));
    setPickedUpVideo(course.topics[topicId].steps[index]);
  };

  useEffect(() => {
    setPickedUpVideo(course.topics[topicId].steps[stepId]);
  }, [course, topicId, stepId]);

  // useEffect(() => {
  //   if (pickedUpVideo.type == "video") {
  //     setTimeout(() => {
  //       playerRef.current.seekTo(3, "seconds");
  //     }, 500);
  //   }
  // }, [pickedUpVideo]);

  useEffect(() => {
    console.log("Restarted");
    if (pickedUpVideo.type == "video") {
      const interval = setInterval(() => {
        if (
          playerRef.current.getCurrentTime() >=
            playerRef.current.getDuration() * DURATION_TO_COMPLETE_VIDEO &&
          !isFinished
        ) {
          dispatch(setTopicStepStatus({ isFinished: true }));
        }
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isFinished, pickedUpVideo]); //dependency array

  // useEffect(() => {
  //   dispatch(fetchCourse({courseId, idToken}));
  //   if (pickedUpVideo.type == "video") {
  //     playerRef.current.seekTo(3, "seconds");
  
  //   }
  // }, []);

  const getMenuItems = (topics) => {
    return topics.map((topic, index) => {
      return topic.title;
    });
  };

  const onItemClickedHandler = (e, index) => {
    e.preventDefault();
    dispatch(setTopicId(index));
  };

  return (
    <>
      <SideMenu
        items={getMenuItems(course.topics)}
        onItemClickedHandler={onItemClickedHandler}
        mainContent={
          <MainContent
            currentCourse={course}
            topicId={topicId}
            pickedUpVideo={pickedUpVideo}
            embeddedVideos={
              <CustomStepper
                onClickHandler={videoNumberHandler}
                pickedUpVideo={pickedUpVideo}
                steps={course.topics[topicId].steps}
              />
            }
            ref={playerRef}
          />
        }
      />
    </>
  );
};

export default Course;
