export const DURATION_TO_COMPLETE_VIDEO = 0.9; // 90% of video duration

export const defaultStateValues = {
    area: "frontend",
    courseId: 0,
    course: {},
    topicId: 0,
    stepIsFinished: false,
    coursesAll: [],
    videoPosition: 0,
    currentStepId: 0,
    pickedUpVideo: {},
    isFinished: false,
};
