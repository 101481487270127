import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

// import { currentArea,currentCourse, currentTopic, currentTopicStepStatus, currentTopicStep } from "./courseSlice";
import { courseTopicStepStatusAll } from "./courseSlice";

const CustomStepper = (props) => {
    const finishedSteps = useSelector(courseTopicStepStatusAll);

    // const topicStepStatus = useSelector(currentTopicStepStatus);

    // const steps = props.topics[TOPIC_ID].steps;
    return <Box sx={{ width: '100%' }}>
    <Stepper activeStep={1} alternativeLabel>
      {props.steps.map((step, index) => {
        console.log("here")
        const labelProps = {};
        // if (isStepFailed(index)) {
          //   labelProps.optional = (
            //     <Typography variant="caption" color="error">
            //       Alert message
            //     </Typography>
            //   );
            
            //   labelProps.error = true;
            // }
            
        const isActive = ()=> {
          return props.pickedUpVideo.title==step.title;
        }
        const isCompleted = (index)=> {
            return finishedSteps[index];
        }
        return (
          <Step  active={isActive()} completed={isCompleted(index)} key={step.title.split(":")[1]} onClick={(e)=>props.onClickHandler(e, index)} >
            <StepLabel {...labelProps}  ><span style={{color:"white"}}> {step.title.split(":")[1]}</span></StepLabel>
          </Step>
        );
      })}
    </Stepper>
  </Box>
};

export default CustomStepper;