import React from "react";
// import { Card } from "react-bootstrap";
import "./Card.css";
import { Card } from "@mui/material";

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (props) => {
  return <React.Fragment>
    <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {props.header}
      </Typography>
      <Typography variant="h5" component="div">
        {props.title}
      </Typography>
      <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {props.text}
      </Typography>
      <Typography variant="body2">
        {/* {props.text} */}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" onClick={(e)=>props.handleUrlClick(e, props.id)}>Abrir</Button>
    </CardActions>
  </React.Fragment>
};

const CustomCard = (props) => {

  return (
    <>
      {/* <Card
        bg='dark'
        key={props.idx}
        text={props.variant.toLowerCase() === "light" ? "dark" : "white"}
        style={{ width: "18rem" }}
        className="mb-2"
        onClick={(e)=>props.handleUrlClick(e, props.id)}
        >
        <Card.Header>{props.header}</Card.Header>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text>
            {props.text}
          </Card.Text>
        </Card.Body>
      </Card> */}
      <Card sx={{ minWidth: 275 }} >
        {card(props)}
      </Card>
    </>
  );
};

export default CustomCard;
