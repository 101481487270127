import { pythonBasicCourseTopics } from "./topicsDefinitions/pythonTopicsv001";



export const courses = [
  {
    area: "Programación",
    subject: "Python",
    title: "🐍 Curso de Python Fundamentos 👨🏼‍🎓",
    description: "Aprende las bases sólidas de Python",
    url: "",
    image: "",
    topics: pythonBasicCourseTopics,
    id: 1,
  },
  // {
  //   area: "Programación",
  //   subject: "Python",
  //   title: "Curso de Python Intermedio",
  //   description: "Aprende temas intermedios de Python",
  //   url: "",
  //   image: "",
  //   topics: pythonBasicCourseTopics,
  //   id: 2,
  // },
];

export const matematicasAreaCourses = [
  {
    area: "Matemáticas",
    subject: "Álgebra",
    title: "Curso de Álgebra Básica",
    description: "Aprende las bases de Álgebra",
    url: "",
    image: "",
    topics: null,
    id: 1,
  },
];

// export const areas = [
//   {
//     name: "Programación",
//     image: "",
//     // id: 1,
//     id: areaId.programacion,
//   },
//   {
//     name: "Matemáticas",
//     image: "",
//     // id: 2,
//     id: areaId.matematicas,
//   },
//   {
//     name: "Física",
//     image: "",
//     // id: 3,
//     id: areaId.fisica,
//   },
//   {
//     name: "Química",
//     image: "",
//     // id: 4,
//     id: areaId.quimica,
//   },
// ];

export const CourseId = {
  python: 1,
};



