import React, {  useState } from "react";
import "./App.css";
import Header from "./components/presentation/header/Header";
// import Card from "./components/card/Card";
// import Carousel from "./components/carousel/Carousel";
import Home from "./views/home/Home";
import SignUp from "./components/authentication/Signup";
import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Navbar from './components/presentation/navbar/Navbar';
import Login from './components/authentication/Login';
import Logout from './components/authentication/Logout';
import EmailVerification from './components/authentication/EmailVerification';
import Creator from './components/presentation/creational/creator/Creator';
import Course from './components/presentation/course/Course';
import slugify from 'slugify';

import {courses} from "./components/presentation/course/CoursesList";

require('dotenv').config() // Todo Verifu this is necessary
const coursesCards = [
  {
    area: "Programacion",
    subject: "Python",
    title: "Curso Básico de Python",
    text: "Aprende a programar en Python desde cero",
    id: "1",
  },
  {
    area: "Programacion",
    subject: "Git",
    title: "Curso de Git para el trabajo en equipo",
    text: "Aprende a usar Git y GitHub desde cero",
    id: "2",
  },
  {
    area: "Programacion",
    subject: "Curso Intermedio de Python",
    title: "Curso Esencial de Git",
    text: "Aprende a usar Git y GitHub desde cero",
    id: "3",
  },
  {
    area: "Programacion",
    subject: "Git",
    title: "Curso Avanzado de Python",
    text: "Aprende los conceptos avanzados de Python",
    id: "4",
  },
  {
    area: "Programacion",
    subject: "HTML",
    title: "Curso Esencial de HTML",
    text: "Aprende HTML desde cero",
    id: "5",
  },
  {
    area: "Programacion",
    subject: "JavaScript",
    title: "Curso Esencial de JavaScript",
    text: "Aprende JavaScript desde cero",
    id: "6",
  },
]
const projects = [
  {
    area: "Programacion",
    stack: ["Python","Git"],
    title: "Aplicación de línea de comandos",
    text: "Crea una aplicacion de linea de comandos con Python",
    idx: "1",
  },
]

const APIURL = "https://qfthxdzu7d.execute-api.us-east-1.amazonaws.com/dev";
var jwtToken;
function App() {
  const [cards, setCards] = useState(courses);
  const [loggedIn, setLoggedIn] = useState(undefined);
  return (
    <Router>
      <div className="App">
        {/* <Navbar/> */}
        <Header title="Cursos" loggedIn={loggedIn}/>
        <Routes>
          <Route exact path="/" element={<Home cards={cards}/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/signup" element={<SignUp/>} />
          {/* <Route exact path="/email-verification" element={<EmailVerification/>} /> */}
          <Route exact path="/logout" element={ <Logout/>} />
          <Route exact path="/email-verification" element={ <EmailVerification/>} />
          <Route exact path="/create" element={ <Creator/>} />
          <Route exact path="/course" element={ <Course/>} />
          <Route exact path="*" element={ <h1>Pagina no encontrada</h1>} />
          {
            cards.map( (oneCard) =>
            <Route exact path={"/" + slugify(oneCard.area + " " +
                                oneCard.subject + " " +
                                oneCard.title, {lower: true})
                              } element={<Course id={oneCard.id}/>} />
            )
          }
        </Routes>
      </div>
    </Router>
  );
}

export default App;
