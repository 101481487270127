import React from "react";
import { useState } from "react";
import { Formik } from "formik";
import Modal from "@mui/material/Modal";
import Course from "../course/Course";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
//   border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  padding: "20px",
  width: "550px",
  minHeight: "300px",
};

const Path = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <h1>Path</h1>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <label for="title">title:</label>
            <input type="text" name="title" value={values.title} />
            {errors.title && touched.title && errors.title}
            <br />
            <label for="description">description:</label>
            <input type="text" name="description" value={values.description} />
            <br />
            {errors.description && touched.description && errors.description}
            <label for="image">image:</label>
            <input type="text" name="image" value={values.image} />
            <br />
            {errors.image && touched.image && errors.image}
            <button onClick={() => setOpen(true)}>Add Course</button>
            <button onClick={() => setOpen(true)}>Create Course</button>
            <br />
            <button type="submit">Cancel</button>
            <br />
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </form>
        )}
      </Formik>
      <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button onClick={() => setOpen(false)}>X</button>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography> */}
          <Course />
          <button onClick={() => setOpen(false)}>Cancel</button>
        </Box>
      </Modal>
    </>
  );
};

export default Path;
