import React, {useState} from 'react';
import {CognitoUser} from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { Navigate, useNavigate } from 'react-router-dom';

const EmailVerification = ()=>{
    const [username, setUsername] = useState();
    const [code, setCode] = useState();
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        let userData = {
            Username: username,
            Pool: UserPool
        };
        let cognitoUser = new CognitoUser(userData);
        e.preventDefault();
        
        cognitoUser.confirmRegistration(code, true, function(err, result) {
            if (err) {
                console.log(err);
                return;
            }
            navigate('/login')
            console.log('call result: ' + result);
        });
    }
    const sendCodeAgain = () => {
        let userData = {
            Username: username,
            Pool: UserPool
        };
        let cognitoUser = new CognitoUser(userData);
        cognitoUser.resendConfirmationCode((err, result)=>{
            if(err) {
                console.log(err)
                return
            }
            console.log(result)
        })
    }
    return (
        <>
            <h1>Email Verification</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input type="text" name="username" id="" onChange={(e)=> setUsername(e.target.value)}/>
                <label htmlFor="code">Verification code:</label>
                <input type="text" name="code" id="" onChange={(e)=> setCode(e.target.value)} />
                <button type="submit">Submit</button>
                <a href='#' onClick={sendCodeAgain}>Send code again.</a>
            </form>
        </>
    )

}

export default EmailVerification;