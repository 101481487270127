import React, {useState} from 'react';
import UserPool from "./UserPool";
import {CognitoUserAttribute} from "amazon-cognito-identity-js";
import { useNavigate } from 'react-router-dom';

 const Signup = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [username, setUsername] = useState();
    const navigate = useNavigate();
    var attributeList = [];
    var attributeEmail =new CognitoUserAttribute({Name:'email',Value:email});
    
    attributeList.push(attributeEmail);

    const handleSubmit = (event) => {
        event.preventDefault();
        UserPool.signUp(username, password, attributeList, null, function(err, data){
                if(err){
                    console.log(err);
                    return
                }
                console.log('Login successful')
                console.log(data);
                navigate('/email-verification');
        });
    }

    return (
        <div>
            <h1>Signup</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <input 
                    value={username}
                    onChange={(e)=>setUsername(e.target.value)}
                    type="text" />
                <label htmlFor="email">Email:</label>
                <input 
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    type="text" />
                <label htmlFor="password">Password:</label>
                 <input 
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    type="password" />
                 <button type="submit">Signup</button>
            </form>
        </div>
    )
 };

 export default Signup;