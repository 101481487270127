import {configureStore, combineReducers} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import authConfig from './components/authentication/authSlice';
import courseConfig  from './components/presentation/course/courseSlice';

// const reducers = {configAuth,} //using redux
const reducers = {authConfig,courseConfig} //Using redux-toolkit
const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
}

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({ reducer: persistedReducer,
                    devTools: true,
                    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                        serializableCheck: {
                            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
                        }
                    })
                });