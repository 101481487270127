export const helloWorldTopic = [
    {
      title: "Python Básico: Hello World",
      type: "video",
      url: "https://www.youtube.com/watch?v=pMigQKj6cjo&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=1&pp=gAQBiAQB",
    },
    
  ];
  
export const dataTypeTopic = [
    {
      title: "Python Básico:Datos Enteros",
      type: "video",
      url: "https://www.youtube.com/watch?v=4IMWS9uTm00&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=2&t=104s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico:  Datos Flotantes",
      type: "video",
      url: "https://www.youtube.com/watch?v=BADzy_5BRtg&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=3&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Datos Booleanos",
      type: "video",
      url: "https://www.youtube.com/watch?v=kwMADhPJWSk&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=4&t=13s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Cadenas de texto",
      type: "video",
      url: "https://www.youtube.com/watch?v=y_ztP9A841w&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=5&t=1s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Listas",
      type: "video",
      url: "https://www.youtube.com/watch?v=xFvHJjn1Js8&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=6&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Diccionarios",
      type: "video",
      url: "https://www.youtube.com/watch?v=97JeuMQKF_s&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=7&t=1s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Tuplas",
      type: "video",
      url: "https://www.youtube.com/watch?v=mi3aY2odmWM&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=8&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Conjuntos",
      type: "video",
      url: "https://www.youtube.com/watch?v=EUzjpJ6KYZw&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=9&t=11s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico:Cuestionario - Video",
      type: "video",
      url: "https://www.youtube.com/watch?v=fpRhgHcmeWE&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=10&t=184s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico:Cuestionario tipos de datos",
      type: "quizz",
      id: 1,
    },
  ];
  
export const controlSentencesTopic = [
    {
      title: "Python Básico:  If",
      type: "video",
      url: "https://www.youtube.com/watch?v=KRuzX0dt2Co&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=11&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: If-Elif",
      type: "video",
      url: "https://www.youtube.com/watch?v=gaCeozbMN_0&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=12&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: If-Else",
      type: "video",
      url: "https://www.youtube.com/watch?v=Uydt7p921YI&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=13&t=6s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico : While",
      type: "video",
      url: "https://www.youtube.com/watch?v=yxkewng3URc&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=14&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: While con Break",
      type: "video",
      url: "https://www.youtube.com/watch?v=skE0GYcemzQ&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=15&t=268s&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: While con  Continue",
      type: "video",
      url: "https://www.youtube.com/watch?v=qOh0dHIGY00&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=16&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Pass",
      type: "video",
      url: "https://www.youtube.com/watch?v=8zTKhNA2cqk&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=17&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: For para Listas",
      type: "video",
      url: "https://www.youtube.com/watch?v=FAdbdcMIAhM&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=18&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: For para Diccionarios",
      type: "video",
      url: "https://www.youtube.com/watch?v=NF2NHAzpSyM&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=19&pp=gAQBiAQB",
    },
    {
      title: "Python Básico: Cuestionario de sentencias de control en Python.",
      type: "video",
      url: "https://www.youtube.com/watch?v=WS4lRgcV2_I&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=20&pp=gAQBiAQB",
    },
  ];
  
export const functionsTopic = [
  {
    // title: "Python Básico: Cómo definir funciones",
    title: "Python Básico: Definición",
    type: "video",
    url: "https://www.youtube.com/watch?v=2XygbbV8kK0&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=21&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Invocación de funciones",
    title: "Python básico: Invocación",
    type: "video",
    url: "https://www.youtube.com/watch?v=hkzeTrH__WU&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=22&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Parámetros de la función",
    title: "Python básico: Parámetros",
    type: "video",
    url: "https://www.youtube.com/watch?v=cHDXNoxtsro&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=23&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico:  Orden de parámetros",
    title: "Python básico:  Orden de los parámetros",
    type: "video",
    url: "https://www.youtube.com/watch?v=DpZARJ_6-O0&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=24&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Parámetros por referencia y valor",
    title: "Python básico: Por referencia y valor",
    type: "video",
    url: "https://www.youtube.com/watch?v=abHCxCbJxcY&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=25&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Funciones de orden superior",
    title: "Python básico: Funciones de orden superior",
    type: "video",
    url: "https://www.youtube.com/watch?v=3lO349oF80o&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=26&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Args y Kwargs",
    title: "Python básico: Args y Kwargs",
    type: "video",
    url: "https://www.youtube.com/watch?v=44oRSHCAuF0&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=27&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Usando args mas kwargs",
    title: "Python básico: Usando args más kwargs",
    type: "video",
    url: "https://www.youtube.com/watch?v=9-onezGv96E&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=28&t=57s&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Desempacado de args y kwargs",
    title: "Python básico: Desempacado de (kw)args y kwargs",
    type: "video",
    url: "https://www.youtube.com/watch?v=NLOPdFVeK-I&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=29&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Type hinting",
    title: "Python básico: Type hinting",
    type: "video",
    url: "https://www.youtube.com/watch?v=WSdFa4_JMMs&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=30&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Docstrings",
    title: "Python básico: Docstrings",
    type: "video",
    url: "https://www.youtube.com/watch?v=1z4lHiCYPr8&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=31&pp=gAQBiAQB	",
  },
  {
    // title: "Python básico: Lambdas",
    title: "Python básico: Lambdas",
    type: "video",
    url: "https://www.youtube.com/watch?v=3JCo2ywgUpw&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=32&t=1s&pp=gAQBiAQB	",
  },
  {
    // title: "Python Básico: Resumen de funciones en Python",
    title: "Python Básico: Resumen",
    type: "video",
    url: "https://www.youtube.com/watch?v=Lxd_5SgSaz8&list=PLDomKgyj2Kx1dXiUJWtX-_-rqHK4kpIAZ&index=33&pp=gAQBiAQB",
  },
];
export const pythonBasicCourseTopics = [
    {title: "Hello World", steps: helloWorldTopic},
    {title: "Tipos de datos", steps: dataTypeTopic},
    {title: "Sentencias de control", steps: controlSentencesTopic},
    {title: "Funciones", steps: functionsTopic},
  ];