/*Quizz component that will display the quizz questions and answers, 
and will allow the user to select an answer and submit it.
The quizz component will receive the quizz data as props from the parent component.
The quizz data will be an array of objects, each object will contain the question and the answers.
Quiz types will be: 
1. Multiple choice - the user will be able to select only one answer.
2. Multiple answers - the user will be able to select multiple answers.
3. True or false - the user will be able to select only one answer.
4. Open question - the user will be able to write an answer.
write the whole code of component
*/
import React, { useState, useEffect } from "react";
import MulitpleChoice from "../question/multipleChoice";
import TrueFalse from "../question/trueFalse";
import { Formik, Field, Form } from "formik";

import { quizzesData } from "./quizSample";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const Quiz = (props) => {
  const QUIZ_ID = 1;
  const quizData = quizzesData.filter((quiz) => quiz.id === QUIZ_ID)[0];
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState( ()=>{
    const result = quizData.questions.map((element, index) => {
      if (element.question.type === "multipleChoice") {
        return <MulitpleChoice question={element.question} />;
      } else if (element.question.type === "trueFalse") {
        return <TrueFalse question={element.question} />;
      }
    })
    return result;
  }
 );

  const getQuizData = (id) => {
    return questions.map((question, index) => {
      if (question.id === id) return question;
    })[0];
  };

  const getQuestion = (question) => {
      if (question.type == "multipleChoice")
        return <MulitpleChoice question={question} />;
      else if (question.type == "trueFalse")
        return <TrueFalse question={question} />;
  };

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1);
  };
  const handlePrevious = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const getQuizControls = (formName) => {
    if (currentQuestion == 0) {
      return <button type="submit">Siguiente</button>
    } else if (currentQuestion == questions.length - 1) {
      return <button  type="submit" >Anterior</button>
    } else {
      return <>
        <button  type="submit" >Anterior</button>
        <button  type="submit" >Siguiente</button> 
      </>

    }
  }

  return (
    <>
      <h1>Quizz</h1>

      <div>
        <h1>Sign Up</h1>
        <Formik
          initialValues={{
            toggle: false,
            checked: [],
          }}
          onSubmit={async (values) => {
            console.log(values);
            await sleep(500);
            alert(JSON.stringify(values, null, 2));
          }}
        >
          {({ values }) => (
            <>
            <Form id="quiz-form">
              { questions[currentQuestion] }
              { getQuizControls("quiz-form" ) }
            </Form>

            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default Quiz;
