import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import { courses as CoursesDef } from "./CoursesList";
import axios from "axios";

import { user, jwtToken, isAuthenticated } from "../../authentication/authSlice";
import { useSelector } from "react-redux";

const API_URL = "https://qfthxdzu7d.execute-api.us-east-1.amazonaws.com/dev/";

export const fetchCourse = createAsyncThunk(
  "course/fetchCourse",
  async ({courseId, idToken}) => {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': idToken,
    };
    const response = await axios.options(
      // `${API_URL}${courseId}`, 
      `${API_URL}`, 
      { headers: headers }
    );
    console.log(response.data);

    return response.data;
  }
);

export const courseSlice = createSlice({
  name: "course",
  initialState: {
    area: "",
    course: CoursesDef[0],
    coursesAll: CoursesDef,
    topicId: 0,
    topicStepsStatus: [],
    isFinished: false,
    courseId: 0,
    currentStepId: 0,
    videoPosition: 3,
    fetchStatus: 'idle',//'idle' | 'loading' | 'succeeded' | 'failed'
  },
  reducers: {
    setArea: (state, action) => {
      state.area = action.payload;
    },
    setCourse: (state, action) => {
      const { courseIndex } = action.payload;
      state.coursesAll.forEach((course, index) => {
        if (course.id === courseIndex) {
          state.course = course;
        }
      });
    },
    setCourseId: (state, action) => {
      state.courseId = action.payload;
    },
    setTopicId: (state, action) => {
      state.topicId = action.payload;
    },
    setCurrentStepId: (state, action) => {
      const courseId = state.courseId;
      const topicId = state.topicId;

      state.course.topics[topicId].currentStepId = action.payload;

    },
    setTopicStepStatus: (state, action) => {
      const { isFinished } = action.payload;
      state.isFinished = isFinished;
      const currentStepId = state.course.topics[state.topicId].currentStepId || 0;
      state.coursesAll[state.courseId].topics[state.topicId].steps[currentStepId].isFinished = isFinished;
    },
    setVideoPosition: (state, action) => {
      const currentStepId = state.course.topics[state.topicId].currentStepId;
      state.coursesAll[state.courseId].topics[state.topicId].steps[currentStepId].videoPosition = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCourse.pending, (state) => {
        state.fetchStatus = "loading";
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        state.fetchStatus = "idle";
        // state.course = action.payload;
      })
      .addCase(fetchCourse.rejected, (state, action) => {
        state.fetchStatus = "failed";
        state.fetchError = action.error.message;
      });

  },

});

export const courseArea = (state) => state.courseConfig.area;
export const currentCourseId = (state) => state.courseConfig.courseId;
export const courseObj = (state) => state.courseConfig.course;
export const courseTopicId = (state) => state.courseConfig.topicId;
export const courseTopicAll = (state) => {
  return state.courseConfig.coursesAll.map((course) => {
    if (course.id === state.courseConfig.courseId) {
      return course.topics;
    }
  });
};
export const courseTopicStepStatus = (state) => state.courseConfig.stepIsFinished;
export const courseTopicStepStatusAll = (state) => {
  const {topicId, courseId, coursesAll} = state.courseConfig;
  return coursesAll[courseId].topics[topicId].steps.map((step) => {
    return step.isFinished || false;
  });
};

export const courseObjAll = (state) => state.courseConfig.coursesAll;
export const courseCurrentTopicStepId = (state) => {
  const topicId = state.courseConfig.topicId || 0; 
  return state.courseConfig.course.topics[topicId].currentStepId 

};
export const courseVideoPosition = (state) => {
  const currentStepId = state.courseConfig.course.topics[state.courseConfig.topicId].currentStepId;
  return state.courseConfig.coursesAll[state.courseId].topics[state.topicId].steps[currentStepId].videoPosition 
};
export const {
  setArea,
  setCourse,
  setTopicId,
  setTopicStepStatus,
  setCurrentStepId,
} = courseSlice.actions;

export const courseConfig = (state) => state.courseConfig.course;

export default courseSlice.reducer;
