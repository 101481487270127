import React from 'react';
import { useState } from 'react';
import { Row, Container } from "react-bootstrap";
import CustomCard from '../../components/presentation/card/Card';
import Carousel from '../../components/presentation/carousel/Carousel';
import slugify from 'slugify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCourse } from "../../components/presentation/course/courseSlice";

const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleUrlClick = (e, id) => {
    e.preventDefault()
    dispatch(setCourse({courseIndex:id}));
    navigate('/course')
  }

  const [cards, setCards] = useState(
    props.cards.map( (oneCard) =>  
    <CustomCard
    // TODO: for data coming ffom dynamoDB adapt this(comment code) to below format
    //  variant="Dark"
    //  header={oneCard.M.area.S + " | " + oneCard.M.subject.S}
    //  title= {oneCard.M.title.S}
    //  text= {oneCard.M.text.S}
    //  idx="1"
    variant="Dark"
    header={oneCard.area + " | " + oneCard.subject}
    title= {oneCard.title}
    text= {oneCard.description}
    url= {slugify(oneCard.area + " " +
                  oneCard.subject + " " +
                  oneCard.title, {lower: true})}
    id={oneCard.id}
    handleUrlClick={handleUrlClick}
    />
    )
  );
   return  <Container fluid>
     <Row className="justify-content-md-center" md="auto">
       <div id="site-carousel">
         <Carousel />
       </div>
     </Row>
     <Row className="justify-content-md-center" md="auto">
       <div id="site-cards">
         {cards}
       </div>
     </Row>
   </Container>;
};

export default Home;