// Sample quizz with MulitpleChoice and TRUEFALSE questions:
export const quizzesData = [
    {
      id: 1,
      title: "Python Basic: Data Types Quizz",
      questions: [
        {
          question: {
            text: "What is the output of the following code?",
            code: "print(9//2)",
            video: "https://www.youtube.com/embed/pMigQKj6cjo",
            image:
              "https://www.python.org/static/community_logos/python-logo-master-v3-TM.png",
            type: "multipleChoice",
            choices: ["4", "4.5", "5", "5.5"],
            answer: "4",
          },
        },
        {
          question: {
            text: "The output of following code is 4.5?",
            type: "trueFalse",
            code: "print(9//2)",
            video: "https://www.youtube.com/embed/pMigQKj6cjo",
            image:
              "https://www.python.org/static/community_logos/python-logo-master-v3-TM.png",
            choices: ["True", "False"],
            answer: "False",
          },
        },
        {
          question: {
            text: "The output of following code is 4.5?",
            type: "trueFalse",
            code: "print(9//2)",
            video: "https://www.youtube.com/embed/pMigQKj6cjo",
            image:
              "https://www.python.org/static/community_logos/python-logo-master-v3-TM.png",
            choices: ["True", "False"],
            answer: "False",
          },
        },
      ],
    },
  ];